<template>
	<div class="container">
		<div class="header">
			<img src="../../assets/imgs/mall.png" alt="">
			<div class="text">
				<p class="title">天地乾坤商城</p>
				<div class="line"></div>
			</div>
		</div>
		
		<!-- <div class="search">
			<div class="text">
				<p>智慧家居</p>
				<div class="line"></div>
				<p>智慧社区</p>
				<div class="line"></div>
				<p>全要素疫情防控</p>
			</div>
			
			<el-input style="width: 295px;" placeholder="请输入内容" v-model="search" class="input-with-select">
			    <el-button slot="append" icon="el-icon-search"></el-button>
			</el-input>
		</div> -->
		
		<div class="main">
			<div class="text-class">
				<div class="name-class">
					<p class="name">{{nowPro.name}}</p>
					<div class="classfy">
						<span v-for="item of nowPro.class" :key="item">{{item}}</span>
					</div>
				</div>
				
				<p class="content">{{nowPro.content}}</p>
				<div class="line"></div>
			</div>
			<div class="details">
				<div class="top">
					<img :src="nowPro.img" alt="">
				</div>
				<div class="bottom">
					<img :src="nowPro.zb" alt="">
					<div class="price-text">
						<p>全国统一零售价：<span style="color: #FC623C;">{{nowPro.price}}</span>元</p>
						<p>质保一年</p>
						<button class="btn" @click="showDownLoad = true">立即购买</button>
					</div>
				</div>
			</div>
			
			<div class="apply">
				<p class="title">申请合作</p>
				<div>
					<el-descriptions :column="2">
					    <el-descriptions-item label="公司名称">天乾地坤科技有限股份公司</el-descriptions-item>
					    <el-descriptions-item label="联系电话">400-066-1909</el-descriptions-item>
					    <el-descriptions-item label="邮箱">duan.liu@cklock.cn</el-descriptions-item>
					    <el-descriptions-item label="地址">河北省承德市双滦区君泰财富广场A区</el-descriptions-item>
					</el-descriptions>
				</div>
			</div>
		</div>
		
		<div v-if="showDownLoad" @click.stop="showDownLoad = false" class="download">
			<div @click.stop="">
				<img src="../../assets/imgs/download.png" alt="">
				<img @click.stop="showDownLoad = false" src="../../assets/imgs/close.png" alt="">
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				search: '',
				proArr: [
					{
						id: '1',
						img: require('@/assets/imgs/cp1.png'),
						zb: require('@/assets/imgs/zb1.png'),
						name: '开关面板',
						content: '灯光集中控制管理,简化操作；可以单独操控某一个灯光，也 可按场景模式切换，全屋回家、离家总控。',
						class: [
							'一键智能开关',
							'二键智能开关',
							'三键智能开关'
						],
						price: '298'
					},
					{
						id: '2',
						img: require('@/assets/imgs/cp2.png'),
						zb: require('@/assets/imgs/zb2.png'),
						name: '情景面板',
						content: '灯光集中控制管理,简化操作；可以单独操控某一个灯光，也 可按场景模式切换，全屋回家、离家总控',
						class: [],
						price: '99'
					},
					{
						id: '3',
						img: require('@/assets/imgs/cp3.png'),
						zb: require('@/assets/imgs/zb3.png'),
						name: '电动窗帘电机',
						content: '智能联动、可通过语音、手机、手动轻拉控制 ，可设定时间 自动控制等，配备静音轨道',
						class: [],
						price: '399'
					},
					{
						id: '4',
						img: require('@/assets/imgs/cp4.png'),
						zb: require('@/assets/imgs/zb4.png'),
						name: '电动窗帘轨道',
						content: '智能联动、可通过语音、手机、遥控、手动轻拉控制 ，可设 定时间自动控制等，配备静音轨道',
						class: [],
						price: '149/米'
					},
					{
						id: '5',
						img: require('@/assets/imgs/cp5.png'),
						zb: require('@/assets/imgs/zb5.png'),
						name: 'NB电动窗帘轨道',
						content: '安装方便、开关门记录、设备联动',
						class: [],
						price: '368'
					},
					{
						id: '6',
						img: require('@/assets/imgs/cp7.png'),
						zb: require('@/assets/imgs/zb7.png'),
						name: '人脸识别',
						content: '口罩识别、人脸识别、体温检测、红外精准测温、高温异常报警',
						class: [],
						price: '3980'
					},
					{
						id: '7',
						img: require('@/assets/imgs/cp6.png'),
						zb: require('@/assets/imgs/zb6.png'),
						name: '多人热成像人脸识别测温仪',
						content: '口罩识别、人脸识别、体温检测、热成像识别、红外精准测温、高温异 常报警',
						class: [],
						price: '7999'
					},
					{
						id: '8',
						img: require('@/assets/imgs/cp8.png'),
						zb: require('@/assets/imgs/zb8.png'),
						name: '超低能耗核酸采样室',
						content: '金属，耐刮伤、耐撞击、耐腐蚀、且有一定的耐湿热、耐污斑、耐霉菌、耐酸碱性。防爆钢化玻璃，视野通透，采样安全快捷。可移动、四角福马轮。室外 / 室内',
						class: [],
						price: '99999'
					},
					{
						id: '9',
						img: require('@/assets/imgs/cp9.png'),
						zb: require('@/assets/imgs/zb9.png'),
						name: '智能地锁',
						content: '精密防水、坚固抗压、远程遥控、 防撞防磕',
						class: [],
						price: '499'
					},
					{
						id: '10',
						img: require('@/assets/imgs/cp10.png'),
						zb: require('@/assets/imgs/zb10.png'),
						name: '直杆道闸',
						content: '起杆快、落杆稳、寿命长、动力足。在强冷气下能正常启动。停电时可手动操作起落杆。散热快、安装简单、无线遥控控制',
						class: [],
						price: '2999'
					},
					{
						id: '11',
						img: require('@/assets/imgs/cp11.png'),
						zb: require('@/assets/imgs/zb11.png'),
						name: '智能鼠标',
						content: '语音翻译、语音录入、语音控制、超级省电、物联IOT',
						class: [],
						price: '238'
					},
					{
						id: '12',
						img: require('@/assets/imgs/cp12.png'),
						zb: require('@/assets/imgs/zb12.png'),
						name: '智能语音音响',
						content: '语音控制端，可语音控制全屋智能设备，可播放背景音乐、 查询天气、新闻、回答问题等',
						class: [],
						price: '499'
					},
				],
				nowPro: {},
				active: '1',
				showDownLoad: false
			}
		},
		
		methods: {
			getPro() {
				if(this.active <= this.proArr.length) {
					this.nowPro = this.proArr[this.active - 1]
				}
			}
		},
		
		created() {
			this.active = this.$route.query.id
			this.getPro()
			
		}
	}
</script>

<style scoped>
.header {
	position: relative;
}

.header img {
	width: 100%;
}

.header .text {
	position: absolute;
	top: 35%;
	left: 15%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.header .text .title {
	height: 48px;
	font-size: 48px;
	font-family: SourceHanSansCN-Bold, SourceHanSansCN;
	font-weight: bold;
	color: #FFFFFF;
	margin-bottom: 28px;
}

.header .text .line {
	width: 452px;
	height: 5px;
	background: linear-gradient(90deg, rgba(255,255,255,0.1300) 0%, #FFFFFF 45%, rgba(255,255,255,0.1600) 100%);
	border-radius: 6px;
}


.search {
	height: 71px;
	background: #F4F8FF;
	padding: 0 211px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.search .text {
	display: flex;
	align-items: center;
}

.search .text p {
	font-size: 17px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #666666;
	cursor: pointer;
}

.search .text .line {
	width: 1px;
	height: 12px;
	background-color: #979797;
	margin: 0 24px;
}

.main {
	width: 1200px;
	margin: 0 auto;
	padding: 57px 50px 81px;
	position: relative;
	box-sizing: border-box;
}

.main .text-class {
	position: absolute;
	top: 120px;
	left: 798px;
}

.main .text-class .name-class {
	display: flex;
	align-items: flex-end;
}

.main .text-class .name-class .name {
	font-size: 24px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #000000;
	margin-right: 16px;
}

.main .text-class .name-class .classfy span {
	font-size: 13px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #184BAB;
	margin-right: 16px;
}

.main .text-class  .content {
	width: 400px;
	font-size: 12px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #666666;
	line-height: 14px;
	margin: 28px 0 31px;
}

.main .text-class .line {
	width: 40px;
	height: 6px;
	background: #184BAB;
}

.bottom {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 85px;
}

.bottom .btn {
	margin-top: 25px;
	width: 144px;
	height: 38px;
	background: linear-gradient(142deg, #3582D5 0%, #184BAB 100%);
	border-radius: 19px;
	line-height: 38px;
	font-size: 24px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #FFFFFF;
	border: none;
}

.price-text {
	margin-left: 100px;
	font-size: 24px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #184BAB;
}

.apply {
	/* height: 312px; */
	background-size: cover;
	padding: 45px 199px 0;
	box-sizing: border-box;
	text-align: center;
}

.apply .title {
	height: 23px;
	font-size: 23px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #333333
}

.apply div {
	margin-top: 42px;
	
}

.el-descriptions {
	font-size: 18px;
}

.download {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(188, 188, 188, .5);
	z-index: 999;
}

.download div {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

</style>